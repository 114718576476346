<template>
  <div>
    <v-row class="ma-0">
      <h3 :class="course.hasConflicts ? 'warning--text' : ''">{{ course.section }}</h3>
      <v-chip v-if="course.hasConflicts" small color="warning" class="ml-3 px-2 font-weight-bold">CONFLICT</v-chip>
      <chip :text="course.enrolment + ' / ' + course.capacity" label enrolment style="position: absolute; right: 30px; height: 28px"/>
    </v-row>
    <v-row class="ma-0 mt-1">
      <p class="text-left text--secondary mt-0 mb-2 flex-fill" style="max-width: 70%">
        {{ course.instructor.length > 0 ? course.instructor.map(prof => prof.displayName).join(' / ') :
        (section.value[0] === 'T' ? 'Assistant, T.' : '---')
        }}
      </p>
      <p v-if="course.waitlist > 0" class="mt-1 mb-0 text--secondary" style="position: absolute; right: 30px">Waitlist: {{ course.waitlist }}</p>
    </v-row>
    <v-row v-for="(time, idx) in course.times" :key="idx" class="pb-1 ma-0">
      <h4 class="font-weight-medium">{{ time.day }}</h4>
      <h4 class="font-weight-regular pl-1"> • {{ time.time }}</h4>
      <h4 class="font-weight-regular text--secondary enrolChip">
        {{ time.location.room.length > 1 ? time.location.room : course.delivery }}
      </h4>
    </v-row>
  </div>
</template>

<script>
import Chip from '@/components/Chip.vue'

export default {
  name: 'CourseOfferingRow',
  components: { Chip },
  props: {
    course: { type: Object, required: true },
    section: { type: Object, required: true }
  }
}
</script>

<style scoped>
  .enrolChip {
    font-weight: bold;
    position: absolute;
    right: 30px;
  }
</style>
