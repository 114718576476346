/** Helper utilities for CSPTree and generating timetables */

/** How we uniquely identify constraints */
const CONSTRAINT_INNER_DELIM = '+'
const CONSTRAINT_OUTER_DELIM = '$'

/**
 * Given course IDs and their section IDs, return a key
 * that uniquely identifies them.
 *
 * @example getKeyForCourseAndSection('CSCA08','LINA01', 'Lec20', 'Lec3')
 *          => 'CSCA08+Lec20$LINA01+Lec3'
 *
 * @param {string} cId1
 * @param {string} cId2
 * @param {string} sec1
 * @param {string} sec2
 */
export function getKeyForCourseAndSection (cId1, cId2, sec1, sec2) {
  return cId1 + CONSTRAINT_INNER_DELIM + sec1 + CONSTRAINT_OUTER_DELIM + cId2 + CONSTRAINT_INNER_DELIM + sec2
}

/**
 * Given course and section ID, return key that uniquely identifies it.
 *
 * @example getUnaryConstrainKey('ANTA01', 'Lec5') => 'ANTA01+Lec5'
 *
 * @param {string} cId The course id.
 * @param {string} secId The section inside the course.
 */
export function getUnaryConstrainKey (cId, secId) {
  return cId + CONSTRAINT_INNER_DELIM + secId
}

export function sectionsOverlap (sec1, sec2) {
  // When either is null, they cannot overlap
  if (!sec1 || !sec2) return false

  for (const time1 of sec1.times) {
    for (const time2 of sec2.times) {
      if (time1.day !== time2.day) continue

      if (time1.start <= time2.start && time1.end > time2.start) return true

      else if (time2.start <= time1.start && time2.end > time1.start) return true
    }
  }

  return false
}
